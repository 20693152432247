@font-face {
  font-family: Alexandria;
  src: url(./assets/Alexandria-VariableFont_wght.ttf);
}

.App {
  width: 100%;
  height: fit-content;
  font-family: Alexandria;
  overflow-x: hidden;
}

h1,
h2,
h3,
p{
  margin: 0;
}

h1 {
  font-weight: 800;
  font-size: 4.306vw;
}

h2 {
  font-weight: 600;
  font-size: 2.517vw;
}

.HeaderContainer {
  width: 100%;
  height: fit-content;
  background-color: #00EEFF;
  background-image: linear-gradient(to bottom, #76e0e7, #00EEFF);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Logo {
  width: 90%;
}

@media (min-width: 768px) {
  .Logo {
    width: 40%;
    /* Width for desktop viewports */
  }
}

.HeaderContents {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 95%;
  height: fit-content;
  align-items: end;
  justify-content: space-between;
  margin-top: -2vw;
}

.pinkHighlight {
  color: #E55B8C;
  text-shadow: #1f1f29 0.3vw 0.3vw 0;
}

.HeaderTextBOX {
  display: flex;
  flex-direction: column;
  color: #1f1f29;
}
.HeaderTextBOX h1{
  text-transform: uppercase;
}
.HeaderImg {
  height: 40vw;

}

.BuyBtn {
  background-color: #E55B8C;
  color: #1f1f29;
  border-radius: 30px;
  border: #fff 0.521vw solid;
  width: 27vw;
  aspect-ratio: 7;
  font-weight: 900;
  font-size: 4.167vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% 0%;

  transition: all 200ms ease-out;
}

.BuyBtn:hover {
  cursor: pointer;
  background-color: #ff0059;
  color: #1b1b1c;
  scale: 110%;
}

.rotate5deg {
  rotate: -5deg;
}

.SMIconsContainer {
  display: flex;
  flex-direction: row;
  width: 27vw;
  gap: 12px;
  margin-bottom: 0.5vw;
}

.SocialMediaBtn {
  width: 5.208vw;
  aspect-ratio: 1;
  background-color: #1f1f29;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  transition: all 150ms linear;
}

.SocialMediaBtn:hover {
  cursor: pointer;
  background-color: #383847;
  scale: 110%;
  rotate: 2deg;
}

.Xicon {
  background-image: url(./assets/Xlogo.png);
}

.Telegramicon {
  background-image: url(./assets/Telegramlogo.png);
}

.ticker-container {
  width: 100%;
  overflow: hidden;
  /* Hide the overflowing text */
  background-image: linear-gradient(to right, #E55B8C, #ba416c);
  color: white;
  border-bottom: 0.625vw solid #1f1f29;
  border-top: 0.625vw solid #1f1f29;
  padding: 0.521vw 0;
}

.ticker {
  display: flex;
  white-space: nowrap;
  /* Prevent the text from wrapping */
}

.ticker-text {
  display: inline-block;
  padding-left: 00%;
  animation: ticker 600s linear infinite;
  /* Apply the scrolling animation */
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
    /* Move to the left end */
  }
}

.AnimationContainer {
  width: 100%;
  aspect-ratio: 1.66666;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./assets/Animation.gif');
}

.Seperator1 {
  width: 100%;
  height: fit-content;
  background-color: #00EEFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.625vw solid #fff;
  border-top: 0.625vw solid #fff;
}

.SeperatorIMG {
  width: 35vw;
}

.reverseIMG {
  transform: scaleX(-1);
}

.SocialsContainer {
  width: 100%;
  background-image: url('./assets/GreenSunray.png');
  background-position: top center;
  background-size: 150%s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContainerHeaders {
  text-shadow: #E55B8C 0 0.313vw 0;
  text-transform: uppercase;
  font-size: 5.506vw;
  margin-top: 2vw;
}

.SMIconsContainer2 {
  display: flex;
  flex-direction: row;
  gap: 2vw;
}

.margintop1 {
  margin-top: 5%;
}

.Footer {
  width: 100%;
  background-color: #1f1f29;
  color: #00EEFF;
  border-top: #00EEFF 1vw solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2vw;
}

.Footer img {
  width: 40%;
}

.Footer p {
  font-size: 12pt;
  text-align: center;
  margin: 0;
}

.ExchangesContainer {
  color: #fff;
  background-image: url('./assets/OrcaArt4.png'), linear-gradient(45deg, #1f1f29, #383847);
  background-position: bottom left, top;
  background-size: 23%, 150%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 0.625vw solid #1f1f29;
  padding-bottom: 2vw;
}

.ExchangeIcon {
  width: 20vw;
  aspect-ratio: 3;
  background-color: #1b1b1c7d;
  backdrop-filter: blur(0.75vw);
  border: #ffffff6f solid 0.2vw;
  transition: all 150ms linear;
}

.ExchangeIcon:hover {
  cursor: pointer;
  background-color: #1b1b1c;
  scale: 105%;
}

.ExchangeIconContainer {
  width: 70vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1vw 0;
  gap: 1vw;
}

.TokenomicsContainer {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #76e0e7, #00EEFF);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.625vw solid #1f1f29;
  padding-bottom: 2vw;

}

.TokenomicsContainer h2 {
  text-align: center;
  color: #1f1f29;
  font-size: 2.5vw;
}

.TokenomicsContent {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  margin-top: 2vw;
  margin-bottom: 0.5vw;
}

.TokenomicsOrcaArt {
  width: 32vw;
  aspect-ratio: 1;
  background-image: url('./assets/OrcaArt5.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.5vw #1f1f29 solid;
}

.TokenomicsData {
  width: 32vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TokenomicsDataItem {
  background-image: linear-gradient(45deg, #1f1f29, #383847);
  width: 100%;
  aspect-ratio: 3;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TokenomicsDataItem h1 {
  font-size: 2.5vw;
  text-transform: uppercase;
}

.TokenomicsDataItem h2 {
  font-size: 1vw;
  color: #fff;
  font-weight: 500;
}

.TokenomicsDataItemO {
  background-image: url('./assets/ORCAATWORK.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  aspect-ratio: 3;
  animation: atwork 250ms linear infinite;
}

@keyframes atwork {
  0% {
    background-size: 56%;
  }

  100% {
    background-size: 50%;
  }
}

.UtilitiesContainer {
  width: 100%;
  height: fit-content;
  background-image: url('./assets/OrcaArt7.png'),linear-gradient(to bottom, #76e0e7, #00EEFF);
  background-position: 120% 180%, top;
  background-size: 40%, 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.625vw solid #1f1f29;
  color:#1f1f29;
  padding-bottom: 2vw;
  animation: 5s linear 1s infinite alternate RidingtheWave;}
@keyframes RidingtheWave {
  0%{
    background-position: 120% 180%, top;
  }
  100%{
    background-position: 120% 100%, top;
  }

}
.GuideContainer {
  width: 100%;
  height: fit-content;
  background-image: url('./assets/OrcaArt6.png'), linear-gradient(45deg, #1f1f29, #383847);
  background-position: -20% 120%, top;
  background-size: 40%, 150%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 0.625vw solid #1f1f29;
  padding-bottom: 2vw;
}

.UtilitiesData {
  width: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1vw;
  align-items: center;
  justify-content: center;
}

.UtilitiesDataItem {
  width: 28vw;
  padding:2vw;
  aspect-ratio: 2.6;
  background-color: hwb(184 6% 39% / 0.5);
  backdrop-filter: blur(0.75vw);
  border-radius: 0.5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:start;
}
.UtilitiesDataItemTitle{
  font-size: 2.083vw;
  font-weight: 800; 
  text-transform: uppercase;
}
.UtitlitiesDataItemSUB{
  font-size: 1.2vw;

}

.GuideData {
  margin-left: 20%;
  width: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1vw;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;

}
.GuideDataItem {
  width: 28vw;
  padding: 2vw;
  aspect-ratio: 1.5;
  background-color: hsla(240, 14%, 14%, 0.4);
  backdrop-filter: blur(0.75vw);
  color: #fff;
  text-align: left;
  border-radius: 2vw;
  border: 0.2vw #ffffff50 solid;
}
.GuideDataItem:hover .GuideDataItemSUB{opacity: 100%;}
.GuideDataItemTITLE{
  font-weight: 800;
  font-size: 3.2vw;
}
.GuideDataItemSUB{
  font-weight: 500;
  font-size: 1.2vw;
  opacity: 75%;
  transition: all 250ms ease-in-out;
}